<template>
  <div class="text-center login">
    <b-row class="login-contend m-0">
      <b-col sm="12" md="8" lg="6" xl="4" class="">
        <b-card class="p-0 p-md-2 p-xl-4">
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
          >
            <b-img
              fluid
              src="@/assets/images/logo/stocks_easy_logo.png"
              alt="Login V2"
            />
          </div>
          <b-card-title class="my-2 font-weight-bold" title-tag="h2">
            Welcome!! Sign-in to continue...
          </b-card-title>
          <!-- <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text> -->

          <!-- form -->
          <validation-observer ref="loginForm" v-slot="{ handleSubmit }">
            <b-form
              class="auth-login-form mt-2 text-left"
              @submit.prevent="handleSubmit(login)"
            >
              <!-- email -->
              <InputField
                :id="'email'"
                :placeholder="'Email'"
                :describedby="'email-feedback'"
                :label="'Email'"
                :name="'Email'"
                :rules="{ required: true, email: true }"
                :modelValue="email"
                @input="(val) => (email = val)"
              />

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <b-link class="d-flex justify-content-end">
                  <small>Forgot Password?</small>
                </b-link>
              </b-form-group>

              <!-- submit buttons -->
              <b-button class="mt-3 p-1 login-btn" type="submit" variant="primary" block>
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Not registered yet? </span>
            <b-link :to="{ name: 'sign-up' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { ref, computed, inject } from "@vue/composition-api";
import InputField from "@/views/components/form/InputField.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import store from '@/store'

/* eslint-disable global-require */
export default {
  components: {
    InputField,
  },
  setup(props,context) {
    const $http = inject('$http');
    const router = context.root.$router;
    const email = ref("");
    const password = ref("");
    const status = ref("");
    const passwordFieldType = ref("password");
    const passwordToggleIcon = computed(() =>
      passwordFieldType.value === "password" ? "EyeIcon" : "EyeOffIcon"
    );
    const togglePasswordVisibility = () => {
      passwordFieldType.value =
        passwordFieldType.value === "password" ? "text" : "password";
    };
		const login = () => {
      console.log('login call version v3');
      $http.post(`/advisor/login`, {email: email.value, password: password.value})
      .then((response) => {
        if(response.status == 200) {
          store.dispatch('auth/login', response)
          router.push('/home')
        } else {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: "Something want to wrong",
              closeOnClick: false,
              timeout: false,
            },
          })
        }
      })
      .catch((error) => {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: `Error`,
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: error.response,
            closeOnClick: false,
            timeout: false,
          },
        })
      });
		}
    return {
      status,
      passwordToggleIcon,
      togglePasswordVisibility,
      email,
      password,
      passwordFieldType,
			login
    };
  },
};
</script>

<style scoped>
.login-btn {
  font-size: 18px;
}
</style>